
/** @jsx jsx */
import MySEO from '../../components/seo'
//import Link from '../../components/Link'
import {StaticImage} from 'gatsby-plugin-image'
import {Box,jsx,Container,Flex,Text,Heading,Grid} from 'theme-ui'
import Layout from '../../components/layout'

export default function TextCollage (){
    return(
        <Layout>
        <Flex>

 <Container m="1">

 <Heading mb="0" variant="normal" as="h1">»Von Herzen – Möge es wieder – zu Herzen gehen!« <br/>
Ludwig van Beethoven </Heading>
<Text variant="normal"  >Textcollage und Musik</Text>

 <MySEO title="Textcollage und Musik" description = "Veranstaltung in Boppard, Kurfürstliche Burg" />
 <Grid gap={[0,3,3]} columns={[1,2, '1fr 1fr']}>
<Box sx={{maxWidth:650,my:30}}>
<StaticImage src="../../images/artists/titelbild-taw.jpg" layout="constrained"
alt="Bild von Aloys Rump" maxWidth="300px" />
</Box>

    
<Box sx={{maxWidth:600,pr:2, mb:40}}>

 
 <Heading marginBottom="0"  variant="normal">Schauspiel</Heading>
 <Text variant="normal">
Beatrice Hutter
</Text>
<Heading marginBottom="0" variant="normal" as="h2">Musik</Heading>
 <Text variant="normal">
Beatrice Hutter
</Text>
<Heading marginBottom="0" variant="normal" as="h2">Text- und Liedauswahl, Inszenierung, Produktionsleitung</Heading>
 <Text variant="normal">
 Walla Heldermann
</Text>
<Heading marginBottom="0" variant="normal" as="h2">Titelbild </Heading>
 <Text variant="normal">
 Aloys Rump
</Text>

</Box>
</Grid>
<Box sx={{maxWidth:600}}>
<Text mt="4" variant="normal">
Ludwig van Beethoven, der Titan, der große Komponist, Erneuerer der Musik, der Klaviervirtuose, der selbstbewusste, ja eigensinnige Star der Wiener Gesellschaft – aber auch ein Schwerenöter, enttäuschter Frauenschwarm und 
immer sehnsuchtsvoller, doch unerfüllter Liebender.
</Text>
<br/><br/>
<Text variant="normal">
Musikalische Variationen und Improvisationen zu Kompositionen Ludwig van Beethovens begleiten auf der Bühne das Spiel der Schauspielerin. Sie werden präsentiert von der Jazz-Gitarristin Katrin Zurborg und dem Schlagwerker Stefan Kohmann. In diesem aus der Interaktion der Musiker mit der Schauspielerin live sich entwickelnden musikalischen Programm stehen die Lieder im Zentrum und sind der Ausgangspunkt der Improvisation. Denn es ist Walla Heldermann in ihrer Inszenierung ein wichtiges Anliegen, gerade in den Liedern Beethovens auch dessen Leichtigkeit und Lebensfreude aufzuzeigen.
</Text>
<Heading mb="0" as="h2" variant="normal">
Karten:
</Heading>
<Text variant="normal">
Museum Boppard, 06742/8015984
</Text>
</Box>


</Container>

</Flex>
</Layout>

    )
}



